import { images } from '../../Layouts/theme';

const shopItems = [
  //? rows 1
  {
    src: images.tumrubthai,
    alt: 'tumrubthai',
  },
  {
    src: images.labPhamacy,
    alt: 'labPhamacy',
  },
  {
    src: images.pAndF,
    alt: 'pAndF',
  },
  {
    src: images.siamdrug,
    alt: 'siamdrug',
  },
  {
    src: images.healthPlus,
    alt: 'healthPlus',
  },
  {
    src: images.sirivej,
    alt: 'sirivej',
  },

  //? rows 2
  {
    src: images.dChain,
    alt: 'dChain',
  },
  {
    src: images.pharmax,
    alt: 'pharmax',
  },
  {
    src: images.fama,
    alt: 'fama',
  },
  {
    src: images.petcharatPharma,
    alt: 'petcharatPharma',
  },
  {
    src: images.familyMart,
    alt: 'familyMart',
  },
  {
    src: images.xta,
    alt: 'xta',
  },

  //? rows 3
  {
    src: images.bangkokdrugstore,
    alt: 'bangkokdrugstore',
  },
  {
    src: images.healthUp,
    alt: 'healthUp',
  },
  {
    src: images.saveDrug,
    alt: 'saveDrug',
  },
  {
    src: images.pure,
    alt: 'pure',
  },
  {
    src: images.onePharmacy,
    alt: 'onePharmacy',
  },
  {
    src: images.boots,
    alt: 'boots',
  },

  //? rows 4
  {
    src: images.goldenPlace,
    alt: 'goldenPlace',
  },
  {
    src: images.jiffy,
    alt: 'jiffy',
  },
  {
    src: images.lotus,
    alt: 'lotus',
  },
  {
    src: images.theMall,
    alt: 'theMall',
  },
  {
    src: images.bigC,
    alt: 'bigC',
  },
  {
    src: images.sevenEleven,
    alt: 'sevenEleven',
  },
];

// const shopItems = [
//   //? rows 1
//   {
//     src: 'tumrubthai.jpg',
//     alt: 'tumrubthai',
//   },
//   {
//     src: 'lab-phamacy.jpg',
//     alt: 'labPhamacy',
//   },
//   {
//     src: 'p-and-f.jpg',
//     alt: 'pAndF',
//   },
//   {
//     src: 'siamdrug.jpg',
//     alt: 'siamdrug',
//   },
//   {
//     src: 'health-plus.jpg',
//     alt: 'healthPlus',
//   },
//   {
//     src: 'sirivej.jpg',
//     alt: 'sirivej',
//   },

//   //? rows 2
//   {
//     src: 'd-chain.jpg',
//     alt: 'dChain',
//   },
//   {
//     src: 'pharmax.jpg',
//     alt: 'pharmax',
//   },
//   {
//     src: 'fama.jpg',
//     alt: 'fama',
//   },
//   {
//     src: 'petcharat-pharma.jpg',
//     alt: 'petcharatPharma',
//   },
//   {
//     src: 'family-mart.jpg',
//     alt: 'familyMart',
//   },
//   {
//     src: 'xta.jpg',
//     alt: 'xta',
//   },

//   //? rows 3
//   {
//     src: 'bangkokdrugstore.jpg',
//     alt: 'bangkokdrugstore',
//   },
//   {
//     src: 'health-up.jpg',
//     alt: 'healthUp',
//   },
//   {
//     src: 'save-drug.jpg',
//     alt: 'saveDrug',
//   },
//   {
//     src: 'pure.jpg',
//     alt: 'pure',
//   },
//   {
//     src: 'one-pharmacy.jpg',
//     alt: 'onePharmacy',
//   },
//   {
//     src: 'boots.jpg',
//     alt: 'boots',
//   },

//   //? rows 4
//   {
//     src: 'golden-place.jpg',
//     alt: 'goldenPlace',
//   },
//   {
//     src: 'jiffy.jpg',
//     alt: 'jiffy',
//   },
//   {
//     src: 'lotus.jpg',
//     alt: 'lotus',
//   },
//   {
//     src: 'the-mall.jpg',
//     alt: 'theMall',
//   },
//   {
//     src: 'big-c.jpg',
//     alt: 'bigC',
//   },
//   {
//     src: '7-eleven.jpg',
//     alt: 'sevenEleven',
//   },
// ];

export default shopItems;

export const contactItems = [
  {
    src: images.line,
    alt: 'line@',
    label: 'Drherb_puayun | Ouayunherb'
  },
  {
    src: images.facebook,
    alt: 'facebook',
    label: 'Ouayun | herbalone'
  },
  {
    src: images.phone,
    alt: 'phone',
    label: '02 455 9554 6<br/>092 257 5345 | 092 262 4412'
  }
]
