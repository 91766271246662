import React, { useEffect, CSSProperties, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { images, spacing, color } from '../Layouts/theme';
import {
  NON_PADDING_LEFT_RIGHT,
  DIV_SHOP_IMAGE_STYLE,
  SPACE_BETWEEN_CONTENT,
  BUTTON_STYLE,
  BODY_2_TH,
  BODY_3_TH,
} from '../Layouts/styled/main';
import { contactItems } from '../components/shop/shop-items';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import styled from 'styled-components';
import ContentImageCenter from '../components/card-content/content-image-center';
import { graphql } from 'gatsby';
import { useMst } from '@/models/root-store';

const ImageBox = styled(Container)`
  @media (max-width: 576px) {
    background-color: transparent;
  }
`;

const BOX: CSSProperties = {
  flexDirection: 'column',
  alignItems: 'center',
}

const IMAGE_BOX: CSSProperties = {
  ...DIV_SHOP_IMAGE_STYLE,
  justifyContent: 'flex-start',
  backgroundColor: 'transparent',
  padding: 0
}

const IMAGE_STYLE: CSSProperties = {
  width: 50,
  height: 50
}

const LABEL: CSSProperties = {
  ...BODY_2_TH,
  paddingLeft: spacing[6]
}

const LABEL_QR: CSSProperties = {
  ...BODY_3_TH,
  marginTop: spacing[4]
}

const Shop = ({ data }) => {
  const { t } = useTranslation();
  const [items, setItems] = useState(24);
  const { ouayUnStore } = useMst()



  const BUTTON: CSSProperties = {
    ...BUTTON_STYLE,
    backgroundColor: color.copyright,
    textDecoration: 'none',
    fontFamily: i18next?.language === 'th' ? 'KanitLight' : 'RobotaRegular',
  };

  const sources = [
    data.mobileImage.edges[0].node.childImageSharp.fluid,
    {
      ...data.desktopImage.edges[0].node.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <main className={'main'}>
      {/* //? section 1 */}
      <ContentImageCenter
        img={{ src: images.headerShopImage, alt: 'shop-heder-image' }}
        source={sources}
        title_name={t('shop_where_to_buy')}
        content={t('shop_where_to_buy_description')}
        headerSmallStyle={{
          textAlign: ouayUnStore?.screenSize?.width > 576 ? 'center' : 'left'
        }}
        contentMediumStyle={{
          maxWidth: 570,
        }}
        contentSmallStyle={{
          maxWidth: 570,
          textAlign: ouayUnStore?.screenSize?.width > 576 ? 'center' : 'left'
        }}
        is_more_detail={false}
      />

      <div style={{ paddingTop: 30 }} className="d-md-none d-block" />

      <Row className="justify-content-center" style={{ ...BOX, marginTop: -spacing[5], marginLeft: 0, marginRight: 0 }}>
        <img
          src={images.qrCode}
          width={120}
          height={120}
          alt={'ouayun-qr-code'}
        />

        <span style={LABEL_QR}>OUAYUN OFFICIAL</span>
      </Row>

      {/* //? section 2 */}
      <section style={{ marginTop: spacing[7] }}>
        <ImageBox fluid>
          <Row className="justify-content-center">
            <Col xs="10" style={{ ...NON_PADDING_LEFT_RIGHT, maxWidth: 1080 }}>
              <Container fluid>
                <Row className="justify-content-center" style={BOX}>
                  {contactItems.map((contact: any, index: number) => {
                    return (
                      <Col
                        lg={{
                          span: 8,
                          offset: 4
                        }}
                        md={{
                          span: 9,
                          offset: 3
                        }}
                        sm={{
                          span: 10,
                          offset: 2
                        }}
                        xs={{
                          span: 11,
                          offset: 1
                        }}
                        style={{ padding: spacing[3] }}
                        key={`contact-store-${index}`}
                      >
                        <div data-aos="fade-up" style={IMAGE_BOX}>
                          <img
                            className='img-contact'
                            src={`${contact.src}`}
                            style={IMAGE_STYLE}
                            alt={contact.alt}
                          />
                          <div style={LABEL} dangerouslySetInnerHTML={{ __html: contact.label }}></div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Container>
            </Col>
          </Row>
        </ImageBox>
      </section>

      <div style={{ paddingTop: 20 }} className="d-md-none d-block" />

      <Row className="justify-content-center" style={{ marginTop: 60, marginLeft: 0, marginRight: 0 }}>
        <Col xs="auto">
          <a
            target="_blank"
            href="https://ouayun.bentoweb.com/th"
            style={BUTTON}
          >
            {t('online_shop')}
          </a>
        </Col>
      </Row>

      <div style={SPACE_BETWEEN_CONTENT} />
    </main>
  );
};
export default Shop;

export const query = graphql`
  query {
    mobileImage: allFile(filter: {relativePath: {eq: "shops/Ouayun_Shop_Cover_1300x576.jpg"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 767, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    desktopImage: allFile(filter: {relativePath: {eq: "shops/Ouayun_Shop_Cover_1300x576.jpg"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1270, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
